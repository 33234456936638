@import '../sass/media-queries';

.RadioGroup {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1);
  // to create a new stacking context
  position: relative;
}

.RadioGroup__option {
  padding: 9px 14px;
  background-color: hsla(160, 90%, 30%, 1);
  border: 1px solid hsla(160, 90%, 20%, 1);
  border-radius: 4px;
  outline: none;

  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  color: white;

  border-radius: 0;
  &:first-child {
    border-radius: 4px 4px 0 0;
  }
  &:last-child {
    border-radius: 0 0 4px 4px;
  }
}

.RadioGroup__option--active {
  z-index: 1;
}
.RadioGroup__option--idle {
  background-color: hsla(160, 20%, 95%, 1);
  color: hsla(160, 90%, 20%, 1);
  z-index: 0;
}
.RadioGroup__option:focus {
  z-index: 2;
}

.Button {
  &:focus {
    box-shadow: 0 0 0 3px hsla(160, 90%, 40%, 0.6);
  }
}

@include respond-above(sm) {
  .RadioGroup {
    display: inline-flex;
    flex-direction: row;
  }
  .RadioGroup__option:first-child {
    border-radius: 4px 0 0 4px;
  }
  .RadioGroup__option:last-child {
    border-radius: 0 4px 4px 0;
  }
}

.MessageModal {
  padding: 15px;
  font-size: 14px;

  p {
    margin-top: 0;
  }
  p.MessageModal__sign-off {
    text-align: right;
    margin-bottom: 0;
    color: #333;
  }

  ul {
    padding: 0 0 0 20px;
    margin: 0 0 14px;
  }
}

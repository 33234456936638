main.RaidParserApp {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  min-height: 100%;
}

header.RaidParserApp__header {
  height: 50px;
  flex: 0 0 50px;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;

  padding: 10px;

  background-color: black;
  background-image: linear-gradient(
    160deg,
    hsla(160, 90%, 35%, 1),
    hsla(160, 90%, 30%, 1)
  );
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  color: #fff;

  z-index: 100;

  > h1 {
    flex: 0 0 auto;
    margin: 0;
    font-size: 20px;
    line-height: 1.5;
    font-weight: 900;
  }

  > .version {
    flex: 0 0 auto;
    font-size: 15px;
    line-height: 2;
    font-weight: 100;
  }
}

article.RaidParserApp__content {
  background-color: #e3e8e6;
}

footer.RaidParserApp__footer {
  flex: 1 0 auto;
  min-height: 50px;
  padding: 15px 10px;

  font-size: 14px;
  font-weight: 600;
  color: #999;

  background-image: linear-gradient(
    hsla(160, 5%, 12%, 1),
    hsla(160, 5%, 15%, 1)
  );

  > .link {
    color: #aaa;
  }
}

body {
  background-color: hsla(160, 5%, 15%, 1);
}

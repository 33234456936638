@import '../sass/media-queries';

.DataTable__header {
  height: 30px;
  padding: 0;
  font-size: 14px;
  line-height: 20px;

  background-color: hsla(170, 3%, 45%, 1);
  color: #fff;

  display: flex;
  flex-direction: row;

  > h1 {
    flex: 1 0 auto;
    margin: 0;
    padding: 5px 10px;

    font-weight: 600;
    text-transform: uppercase;
  }
}

.DataTable__copy-control {
  border: none;
  border-radius: 0;
  border-left: 1px solid hsla(0, 100%, 0%, 0.2);
  background-color: hsla(0, 0%, 0%, 0.1);
  z-index: 1;
  color: hsla(0, 100%, 100%, 0.7);

  &:hover,
  &:focus,
  &:active {
    color: white;
  }
  &:hover {
    background-color: hsla(0, 0%, 0%, 0.2);
  }
  &:active {
    background-color: hsla(0, 0%, 0%, 0.3);
  }
}

.DTSortControl {
  padding: 5px 10px;
  position: relative;
  z-index: 10;
}

.DataTable__scroll-wrapper {
  overflow-y: scroll;
}

.DataTable__table {
  border-collapse: collapse;
  font-size: 14px;
  width: 100%;

  > thead {
    background-color: hsla(170, 3%, 45%, 0.1);
  }

  > tbody > tr:hover {
    background-color: #ddd;
  }

  td,
  th {
    border: none;
    border-bottom: 1px solid #e2e2e2;
    border-right: 1px solid #f6f6f6;
    background-color: transparent;
    text-align: left;
  }

  th {
    font-weight: 600;
    color: #666;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: -0.5px;
    padding: 5px 6px;
    vertical-align: bottom;
  }

  td {
    padding: 5px 7px;
  }
}

td.DataTable__cell--number {
  text-align: right;
  font-variant-numeric: tabular-nums;
}

th.TDHeaderCell {
  color: #666;
  padding-top: 11px;
  border-bottom: 2px solid #bcc2c0;
  transition: border-bottom-color 100ms;
}

th.TDHeaderCell--no-sort {
  color: #888;
  cursor: not-allowed;
}

th.TDHeaderCell--sorted {
  background-color: hsla(230, 100%, 95%, 1);
  border-bottom-color: hsla(230, 100%, 50%, 1);
}

th.TDHeaderCell--sorted-reverse {
  background-color: hsla(10, 70%, 95%, 1);
  border-bottom-color: hsla(10, 100%, 40%, 1);
}

.DataTable__footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e2e2e2;

  font-size: 14px;
  color: #666;
  font-size: 12px;
  background-color: hsla(170, 3%, 45%, 0.1);

  > span:first-child {
    padding: 5px 6px;
    flex: 0 0 auto;
  }
}

.DTPagination {
  display: flex;
  flex: 0 0 auto;

  padding: 3px 0;
}

.DTPagination__Button {
  border: none;
  background-color: transparent;
  outline: none;
  border-radius: 2px;

  padding: 2px 5px;
  margin: 0 3px;

  &:focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
}

.DTPagination__Button {
  display: none;
}

.DTPagination__Button--important,
.DTPagination__Button--current {
  display: inline-block;
}

.DTPagination__Button--disabled {
  cursor: not-allowed;
  color: #bbb;
}

@include respond-above(sm) {
  .DTPagination__Button {
    display: inline-block;
  }

  .DTPagination__Button--current {
    background-color: hsla(160, 30%, 85%, 1);
    font-weight: bold;
    color: hsla(160, 90%, 27%, 1);
  }
}

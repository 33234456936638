@import '../sass/media-queries';

.ToastsManager__ToastContainer {
  position: fixed;
  bottom: 15px;
  right: 15px;
  left: 15px;
}

.Toast {
  display: flex;
  align-items: center;
  margin: 0 0 4px;
  border-radius: 2px;
  padding: 5px 8px;

  background-color: hsla(160, 3%, 50%, 1);
  color: #fff;

  font-size: 12px;
  line-height: 1.5;
  font-weight: 600;

  > span {
    flex: 1 0 auto;
  }

  > .icon {
    flex: 0 0 auto;
    margin-left: 4px;
  }
}

.Toast--success {
  background-color: hsla(160, 90%, 35%, 1);
}

.Toast--error {
  background-color: hsla(10, 100%, 35%, 1);
}

@include respond-above(sm) {
  .ToastsManager__ToastContainer {
    left: unset;
    width: 250px;
  }
}

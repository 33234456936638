.Button {
  margin: 0;
  padding: 9px 14px;
  background-color: hsla(160, 90%, 30%, 1);
  border: 1px solid hsla(160, 90%, 20%, 1);
  border-radius: 4px;
  outline: none;

  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  color: white;

  &:focus {
    box-shadow: 0 0 0 3px hsla(160, 90%, 40%, 0.6);
  }
}

.ParserConfigs {
  padding: 10px;

  > ul {
    font-size: 14px;
    padding: 0 5px 0 25px;
  }
}

.StrategyTable {
  font-size: 14px;
  width: 100%;

  td,
  th {
    width: 10%;

    &:first-child {
      width: 20%;
    }
  }
}

.off-strat {
  color: #bbb;
  background-color: #f6f6f6;
}
.on-strat {
  color: #333;
}

h3 {
  margin: 0 0 0.5em;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  color: hsla(160, 90%, 20%, 1);
}

.ParserConfigs__misc-config {
  display: flex;

  > span {
    display: block;
    flex: 1 0 auto;
  }

  > input {
    display: block;
  }
}

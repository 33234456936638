@import '../sass/media-queries';

.RaidDataInput {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.RaidDataInput__input {
  flex: 0 0 100%;
  padding: 8px 10px;
  resize: vertical;

  font-size: 16px;
}

.RaidDataInput__status {
  flex: 1 0 auto;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.RaidDataInput__status--success {
  color: hsla(160, 90%, 25%, 1);
}

.RaidDataInput__status--error {
  color: hsla(10, 100%, 50%, 1);
}

.RaidDataInput__clear-control {
  flex: 0 0 auto;
  margin-top: 10px;
}

@include respond-above(sm) {
  .RaidDataInput {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .RaidDataInput__status {
    margin-top: 10px;
    padding-left: 10px;
    text-align: left;
  }
}

@import '../sass/media-queries';

.Modal__backdrop {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 5px;
  background-color: hsla(0, 0, 0, 0.8);
  z-index: 100;
}

.Modal {
  max-width: 100%;
  width: 640px;
  margin: 10px auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;

  > header {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #aaa;
    background-color: hsla(160, 90%, 25%, 1);
    background-image: linear-gradient(
      160deg,
      hsla(160, 90%, 30%, 1),
      hsla(160, 90%, 27%, 1)
    );

    color: #fff;
    font-size: 16px;

    > h2 {
      flex: 1 0 auto;
      margin: 0;
      font-size: 1em;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }

    > .icon {
      flex: 0 0 auto;
      opacity: 0.75;
      margin-right: 5px;
    }
  }
}

.Modal--danger-variant {
  > header {
    background-color: #aaa;
    background-color: hsla(160, 90%, 25%, 1);
    background-image: linear-gradient(
      160deg,
      hsla(10, 100%, 45%, 1),
      hsla(10, 100%, 35%, 1)
    );
  }
}

.Modal--bare-variant {
  width: unset;
  > header {
    display: none;
  }
}

@include respond-above(sm) {
  .Section {
    width: calc(100% - 20px);
    max-width: 1080px;
  }
}

@import '../../sass/media-queries';

.ResultsView__controls {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
}

.ResultsView__report-notes {
  font-size: 14px;
  padding: 0 15px 0 35px;
}

.ResultsView__no-data {
  padding: 15px 10px;
  font-size: 16px;
  text-align: center;
  color: #555;
  font-style: italic;
}

.danger {
  color: red;
}

@include respond-above(sm) {
  .ResultsView__controls {
    flex-direction: row;
  }
}

@import '../sass/media-queries';

.Table__scroll-wrapper {
  max-width: 100%;
  overflow-x: scroll;
}

.Table {
  border-collapse: separate;

  th,
  td {
    padding: 5px 7px;
    border: 1px solid #eee;
  }

  th {
    background-color: #ddd;
    vertical-align: bottom;

    .r90up {
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      text-align: left;
    }
  }
}

@include respond-above(sm) {
  .Table__scroll-wrapper {
    overflow-x: visible;
  }
}
